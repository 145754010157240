<template>
  <div>
    <h2>Create New Invoice</h2>
    <hr>
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <h2>Customer Billing Account</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div><strong>Claim: </strong>{{ caseObject.ClaimFileNo }}</div>
            <div><strong>Client: </strong>{{ caseObject.Branch && caseObject.Branch.Client.Name }}</div>
            <div><strong>Branch: </strong>{{ caseObject.Branch && caseObject.Branch.Name }}</div>
            <div><strong>Requestor: </strong>{{ caseObject.Branch && caseObject.Branch.Requester.Name }}</div>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <h2>Case Budget / Requestor</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span v-if="caseObject.ClaimFileNo">
              <strong>Case Budget: </strong>${{ caseObject.BudgetDollars + ".00 /" + caseObject.BudgetHours + ".00 Hours" }}
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span v-if="remainingHours">{{ remainingHours.AuthorizedHours }}.00 hrs authorized, {{ remainingHours.RemainingHours }}.00 hrs remaining</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <strong>Billing Notes (Client):</strong>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="caseObject.FileNumber"
        class="billingNotes"
      >
        <div
          v-text="caseObject.Branch.Client.BillingNotes"
          class="billing-notes-text"
          contenteditable="false"
          :readonly="true"
        />
      </b-col>
    </b-row>
    <b-row class="pt-1">
      <b-col>
        <strong>Billing Notes (Branch):</strong>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="caseObject.FileNumber"
        class="billingNotes"
      >
        <div
          id="textAreaBranch"
          v-text="caseObject.Branch.BillingNotes"
          class="billing-notes-text"
          contenteditable="false"
          :readonly="true"
        />
      </b-col>
    </b-row>
    <b-row class="pt-1">
      <b-col>
        <strong>Billing Notes (Requestor):</strong>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="caseObject.FileNumber"
        class="billingNotes"
      >
        <div
          v-text="caseObject.Branch.Requester.BillingNotes"
          class="billing-notes-text"
          contenteditable="false"
          :disabled="true"
        />
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <strong>Build Invoice</strong>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span>Check items to include on invoice and adjust quantity and rates.</span>
      </b-col>
    </b-row>
    <b-row class="p-1">
      <!-- Table -->
      <b-col class="white-bg-container">
        <b-row>
          <b-col md="1">
            <b-form-checkbox
              id="checkAll"
              v-model="CheckAll"
              name="checkAll"
            >
              All
            </b-form-checkbox>
          </b-col>
          <b-col md="2">
            Action/Expense
          </b-col>
          <b-col md="3">
            Invoice Item
          </b-col>
          <b-col md="1">
            Service Date
          </b-col>
          <b-col md="2">
            Description
          </b-col>
          <b-col md="1">
            Quantity
          </b-col>
          <b-col md="1">
            Rate
          </b-col>
          <b-col md="1">
            Total
          </b-col>
        </b-row>
        <b-row
          v-for="(item, index) of Actions"
          :key="index"
        >
          <b-col>
            <b-row class="pt-1">
              <b-col md="1">
                <span>
                  <b-form-checkbox
                    :id="'checkAll' + item.ActionID"
                    v-model="item.checked"
                    name="checkAll"
                  />
                </span>
              </b-col>
              <b-col md="2">
                <span>
                  <router-link :to="'/cases/edit-action/' + item.ActionID">
                    <span class="text-nowrap">{{ item.ActionFileNumber }}</span>
                  </router-link>
                  - {{ item.Title }}
                </span>
              </b-col>
              <b-col md="3">
                <span>
                  <v-select
                    :id="'invoiceItem' + item.ActionID"
                    v-model="item.InvoiceItem"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="invoiceItemOptions"
                    placeholder=""
                    :name="'invoiceItem' + item.ActionID"
                  />
                </span>
              </b-col>
              <b-col md="1">
                <span>{{ item.ScheduledDate | dateFormat }}</span>
              </b-col>
              <b-col md="2">
                <span>
                  <b-form-input
                    :id="'description' + item.ActionID + item.ID"
                    v-model="item.Description"
                    class="descriptionInput"
                    type="text"
                    placeholder=""
                    :name="'description' + item.ActionID + item.ID"
                  />
                </span>
              </b-col>
              <b-col md="1">
                <span>
                  <b-form-input
                    :id="'quantity' + item.ActionID"
                    v-model="item.Quantity"
                    type="number"
                    @change="onInputChange(index)"
                    placeholder=""
                    :name="'quantity' + item.ActionID"
                  />
                </span>
              </b-col>
              <b-col md="1">
                <span>
                  <b-form-input
                    :id="'rate' + item.ActionID"
                    v-model="item.Rate"
                    type="number"
                    placeholder=""
                    :name="'rate' + item.ActionID"
                  />
                </span>
              </b-col>
              <b-col md="1">
                <span>
                  <b-form-input
                    :id="'total' + item.ActionID"
                    :value="totalCount(item)"
                    type="number"
                    placeholder=""
                    :readonly="true"
                    :name="'total' + item.ActionID"
                  />
                </span>
              </b-col>
            </b-row>
            <div v-if="item.checked">
              <b-row
                v-for="(subItem, subIndex) of subActions"
                :key="subIndex + subItem.ID"
              >
                <b-col v-if="subItem.ActionID === item.ActionID">
                  <b-row class="pt-1">
                    <b-col md="1">
                      <span>
                        <b-form-checkbox
                          :id="'checkAll' + subItem.ActionID + subItem.ID"
                          v-model="subItem.checked"
                          name="checkAll"
                        />
                      </span>
                    </b-col>
                    <b-col md="2">
                      <span />
                    </b-col>
                    <b-col md="3">
                      <span>
                        <v-select
                          :id="'invoiceItem' + subItem.ActionID + subItem.ID"
                          v-model="subItem.InvoiceItem"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="invoiceItemOptions"
                          placeholder=""
                          :name="'invoiceItem' + subItem.ActionID + subItem.ID"
                        />
                      </span>
                    </b-col>
                    <b-col md="1">
                      <span />
                    </b-col>
                    <b-col md="2">
                      <span>
                        <b-form-input
                          :id="'description' + subItem.ActionID + subItem.ID"
                          v-model="subItem.Description"
                          class="descriptionInput"
                          type="text"
                          placeholder=""
                          :name="'description' + subItem.ActionID + subItem.ID"
                        />
                      </span>
                    </b-col>
                    <b-col md="1">
                      <span>
                        <b-form-input
                          :id="'quantity' + subItem.ActionID + subItem.ID"
                          v-model="subItem.Quantity"
                          type="number"
                          :name="'quantity' + subItem.ActionID + subItem.ID"
                          placeholder=""
                          :step="Number.isInteger(Number(subItem.Quantity)) ? '1' : '0.01'"
                          @change="onInputChangeSubItem(subItem.ActionID, subItem.ID)"
                        />
                      </span>
                    </b-col>
                    <b-col md="1">
                      <span>
                        <b-form-input
                          :id="'rate' + subItem.ActionID + subItem.ID"
                          v-model="subItem.Rate"
                          type="number"
                          :step="Number.isInteger(Number(subItem.Rate)) ? '1' : '0.01'"
                          placeholder=""
                          :name="'rate' + subItem.ActionID + subItem.ID"
                        />
                      </span>
                    </b-col>
                    <b-col md="1">
                      <span>
                        <b-form-input
                          :id="'total' + subItem.ActionID + subItem.ID"
                          :value="totalCount(subItem)"
                          type="number"
                          :readonly="true"
                          placeholder=""
                          :name="'total' + subItem.ActionID + subItem.ID"
                        />
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row class="pt-1">
          <b-col md="8"></b-col>
          <b-col md="2">
            <span>
              Approximate Total
            </span>
          </b-col>
          <b-col>
            <span>
              <b-form-input
                id="total"
                v-model="total"
                type="number"
                :readonly="true"
                placeholder=""
                name="total"
              />
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col md="3">
        <b-button
          type="button"
          variant="primary"
          class="w-100"
          :disabled="createInvoiceBtn"
          @click="createInvoice()"
        >
          <span>Create Invoice</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BFormCheckbox, BFormInput, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  filters: {
    dateFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
        if (date) return date.toLocaleDateString("en-US", options);
      }
      if (val) return val.substr(0, 10);
      return val
    },
  },
  data() {
    return {
      CaseID: "",
      caseObject: {},
      CheckAll: false,
      Actions: [],
      subActions: [],
      remainingHours: {},
      invoiceItemOptions: [],
      createInvoiceBtn: false,
      total: 0,
      itemTotal: 0,
      subTotal: 0,
    }
  },
  watch: {
    CheckAll(val) {
      this.Actions = this.Actions.map(item => {
        return {...item, "checked": val}
      })
    },

    itemTotal() {
      this.total = this.itemTotal + this.subTotal
    },

    subTotal() {
      this.total = this.itemTotal + this.subTotal
    },

    Actions: {
      handler(val) {
        let totalRes = 0;
        val.map(item => {
          if (item.checked) {
            totalRes += Number(item.Quantity) * Number(item.Rate);
          }
          if (item.checked !== item.prevChecked) {
            if (item.checked) {
              this.getExpenses(item.ActionID)
            } else {
              this.removeExpenses(item.ActionID)
            }
            item.prevChecked = item.checked
          }
          if (item.prevQuantity !== item.Quantity) {
            return this.getRate(item)
          }
          if (item.prevInvoiceItem !== item.InvoiceItem) {
            return this.getDescription(item);
          }
          if (item.prevDescription !== item.Description) {
            return this.changeDescription(item)
          }
        })
        this.itemTotal = totalRes;
      },
      deep: true
    },

    subActions: {
      handler(val) {
        if (val) {
          let totalRes = 0;
          val.map((item, index) => {
            if (item.checked && this.Actions.filter(i => ((i.ActionID === item.ActionID) && i.checked))[0]) {
              totalRes += Number(item.Quantity) * Number(item.Rate);
            }
            if (item.prevInvoiceItem !== item.InvoiceItem) {
              return this.getDescription(item)
            }
            if (item.prevDescription !== item.Description) {
              return this.changeDescription(item)
            }
            if (item.prevQuantity !== item.Quantity) {
              return this.getRate(item)
            }
          })
          this.subTotal = totalRes;
        }
      },
      deep: true
    },
  },
  created() {
    this.CaseID = this.$route.params.caseId;
    this.loadCase();
    this.loadActionLogs();
    this.getRemainingHours();
    this.getInvoiceItems();
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    loadCase() {
      apiService
          .get("case/" + this.CaseID + "/detail")
          .then((res) => {
            this.caseObject = res.data;
          })
    },
    loadActionLogs() {
      apiService
          .get("actions/case/invoice/" + this.CaseID)
          .then((res) => {
            this.Actions = res.data.map(item => {
              this.fillSubActions(item.ActionID);
              return {
                ...item,
                "Quantity": 1,
                "prevQuantity": 1,
                "checked": false,
                "prevChecked": false,
                "Description": "",
                "prevDescription": "",
                "InvoiceItem": "",
                "prevInvoiceItem": "",
                "type": "Action",
                "modified": false,
                "Rate": 0,
              };
            });
            this.Actions.sort((a, b) => a.ScheduledDate > b.ScheduledDate ? 1 : -1)
          });
    },
    fillSubActions(ActionID) {
      for (let i = 1; i <= 7; i++) {
        this.subActions.push({
          "ID": i,
          "ActionID": ActionID,
          "LineAccountName": "",
          "Description": "",
          "prevDescription": "",
          "Quantity": 1,
          "prevQuantity": 1,
          "Rate": 0,
          "checked": false,
          "prevInvoiceItem": "",
          "modified": false,
          "InvoiceItem": "",
        })
      }
    },

    fillTravelTime(ActionID) {
      const TravelTime = this.invoiceItemOptions.filter(i => i.title === "Travel Time")[0];
      const Mileage = this.invoiceItemOptions.filter(i => i.title === "Mileage")[0];
      this.subActions = this.subActions.map(item => {
        if (item.ActionID === ActionID) {
          if (item.ID === 1) {
            item.InvoiceItem = TravelTime
            this.Actions.map(i => {
              if (i.ActionID === ActionID) item.Quantity = Number(i.TravelTime);
            })
          }
          if (item.ID === 2) {
            item.InvoiceItem = Mileage
            this.Actions.map(i => {
              if (i.ActionID === ActionID) item.Quantity = Number(i.Mileage);
            })
          }
        }
        return item
      })
    },

    getRemainingHours() {
      apiService
          .get("case/" + this.CaseID + "/remaining-hours")
          .then((res) => {
            this.remainingHours = res.data;
          })
    },
    getInvoiceItems() {
      apiService
          .get("invoice/billing-items")
          .then((res) => {
            this.invoiceItemOptions = res.data.map(i => ({title: i.Name, value: i.ID, SalesPrice: i.SalesPrice, Description: i.Description}));
          })
    },
    getExpenses(actionID) {
      apiService
          .get("action/" + actionID + "/expenses")
          .then(res => {
            if (res.data.length > 0) {
              res.data.map(item => {
                const obj =  {
                  ...item,
                  "ID": item.ExpenseID,
                  "Quantity": 1,
                  "prevQuantity": 1,
                  "checked": false,
                  "prevInvoiceItem": "",
                  "InvoiceItem": "",
                  "type": "Expenses",
                  "ActionID": actionID,
                  "ScheduledDate": item.ExpenseDate,
                  "modified": !!item.Description,
                  "prevDescription": item.Description,
                  "Rate": item.Amount,
                }
                const index = this.subActions.indexOf(this.subActions.find(i => i.ActionID === actionID))
                this.subActions.splice(index, 0, obj)
              })
            }
          })
      this.fillTravelTime(actionID)
    },
    removeExpenses(actionID) {
      this.subActions = this.subActions.filter(i => this.filterExpenses(i, actionID))
    },
    filterExpenses(item, actionID) {
      if (item.type === "Expenses") {
        if (item.ActionID === actionID) {
          return false
        }
      }
      return true
    },

    getRate(val) {
      if ((val.Quantity && !val.Rate) || (val.prevInvoiceItem.Description !== val.InvoiceItem.Description)) {
        val.Rate = Number(this.invoiceItemOptions.filter(i => i.value === val.InvoiceItem.value)[0]?.SalesPrice);
        val.prevQuantity = val.Quantity;
        return val;
      }
      return val;
    },

    getDescription(val) {
      if (!val.modified || !val.Description) {
        val.modified = false;
        if (val.InvoiceItem && val.InvoiceItem.value) {
          val = this.getRate(val);
          val.Description = this.invoiceItemOptions.filter(i => i.value === val.InvoiceItem.value)[0].Description
        } else {
          val.Description = "";
          val.Rate = 0;
        }
      }
      val.prevInvoiceItem = val.InvoiceItem;
      return val;
    },

    totalCount(val) {
      if (val.Quantity && val.Rate) {
        const output = Number(val.Quantity) * Number(val.Rate);
        return +output.toFixed(2)
      }
      return 0;
    },

    changeDescription(val) {
      if (val.Description !== this.invoiceItemOptions.filter(i => i.value === val.InvoiceItem.value)[0].Description) {
        val.modified = true;
      }
      return val;
    },

    createInvoice() {
      const InvoiceItems = [];
      const invalidInvoiceItems = [];
      this.Actions.forEach(item => {
        if (item.checked || (item.InvoiceItem && item.InvoiceItem.title)) {
          if (item.checked && item.InvoiceItem && item.InvoiceItem.title && item.Quantity && (Number(item.Quantity) > 0)) {
            InvoiceItems.push({
              "LineAccountName": item.InvoiceItem.title,
              "Description": item.Description,
              "Quantity": Number(item.Quantity),
              "Rate": item.Rate,
              "SourceActionID": item.ActionID,
            })
          } else {
            invalidInvoiceItems.push(item)
          }
          this.subActions.forEach(subItem => {
            if (subItem.ActionID === item.ActionID) {
              if (subItem.checked && subItem.InvoiceItem && subItem.InvoiceItem.title) {
                if (subItem.Quantity && (Number(subItem.Quantity) > 0)) {
                  InvoiceItems.push({
                    "LineAccountName": subItem.InvoiceItem.title,
                    "Description": subItem.Description,
                    "Quantity": Number(subItem.Quantity),
                    "Rate": subItem.Rate,
                    "SourceActionID": item.ActionID,
                  })
                } else {
                  invalidInvoiceItems.push(subItem)
                }
              }
            }
          })
        }
      })

      const postData = {
        "ClientID": this.caseObject.Branch.Client.ClientID,
        "BranchID": this.caseObject.Branch.BranchID,
        "CaseID": this.CaseID,
        "InvoiceItems": InvoiceItems,
      }

      if (invalidInvoiceItems.length) {
        const checkedError = invalidInvoiceItems.filter(i => i.checked === false);
        const invoiceError = invalidInvoiceItems.filter(i => !i.InvoiceItem || (i.InvoiceItem && !i.InvoiceItem.title) || !i.Rate);
        const quantityError = invalidInvoiceItems.filter(i => !i.Quantity || Number(i.Quantity) < 0);
        if (checkedError.length) {
          this.showToast('danger', 'top-center', 4000, 'No Action selected.');
        } else if (invoiceError.length) {
          this.showToast('danger', 'top-center', 4000, 'Selected Action has incomplete data. Blank data not allowed.');
        } else if (quantityError.length) {
          this.showToast('danger', 'top-center', 4000, 'Quantity must be a positive number. Zero, blank, negative are not allowed.');
        }
        return;
      }

      if (!InvoiceItems.length) {
        this.showToast('danger', 'top-center', 4000, 'No Action selected.');
        return;
      }

      this.createInvoiceBtn = true;
      apiService
          .post("invoice/create", postData)
          .then((res) => {
            if (res) {
              this.showToast('success', 'top-center', 4000, 'Invoice Saved!');
              this.$router.push("/cases/" + this.CaseID + "/detail")
            }
            this.createInvoiceBtn = false;
          })
    },

    onInputChange(index) {
      const value = Number(this.Actions[index].Quantity);
      if (!value || value < 0) {
        this.Actions[index].Quantity = null
        this.showToast('warning', 'top-center', 4000, 'Quantity must be a positive number. Zero, blank, negative are not allowed.');
      }
    },

    onInputChangeSubItem(ActionID, ID) {
      const index = this.subActions.findIndex(el => el.ActionID === ActionID && el.ID === ID)
      const value = Number(this.subActions[index].Quantity);
      if (!value || value < 0) {
        this.subActions[index].Quantity = null
        this.showToast('warning', 'top-center', 4000, 'Quantity must be a positive number. Zero, blank, negative are not allowed.');
      }
    },
  },
}
</script>

<style scoped>
span {
  font-weight: 400;
}
.descriptionInput {
  border: none;
  background: none;
  padding: 0 0 0 0.5rem;
  margin: 0;
  height: auto;
}
.billingNotes {
  font-weight: 400;
}
.billing-notes-text {
  min-height: 2.5rem;
  height: max-content;
  width: 100%;
  border: none;
  background: none;
  border: none;
  background: none;
  white-space: pre-wrap;
  outline: none;
}
</style>